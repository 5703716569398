import superagent from 'superagent';
import intercept from 'superagent-intercept';
import { environment } from './environment';
import { bugsnagClient } from './utils/bugsnag';
import { jwtDecode } from './utils/jwt';
import { history, store } from './store';
import { BANNER_HIDE, BANNER_SHOW } from './constants/actionTypes';
import { useState, useEffect, useRef } from 'react';
import { getLocalKey, setLocalKey } from './ManageCache';
import isEqual from 'lodash/isEqual';
/*******************************************************
 * Endpoint Versioning
 * PLEASE KEEP THIS UP TO DATE!!!!!!!
 * If you add a new endpoint, BE SURE TO ADD IT HERE, AND UTILIZE THIS FEATURE!
 * Actual versions moved to /apiVersions.json
 ******************************************************/
import apiVersion from './apiVersions.json';

// DEFAULT FALLBACK. -- everything is now on v1
const defaultVersion = 'v1';

/* Generates the Version number for use in the mock API
 * Curry that bad boy...
 *
 * const ticketRoutes = buildRoute({baseRouteName: 'Ticket', baseRoute: 'tickets'});
 *
 * then use it like so:
 *
 * router.get(`${buildRoute('createNote')}/create_note`, async ctx => { ... });
 *
 */
export const buildRoute = ({ baseRouteName, baseRoute }) => routeName =>
  `/${apiVersion[baseRouteName][routeName] || defaultVersion}/${baseRoute}`;

// export this istance of superagent
export { superagent };

// *****************************************************
// Utils and setup
// *****************************************************
let token = null;
let imitateClientId = null;
let _clientId = null;

export const setClientIdAPI = newClientId => {
  _clientId = newClientId;
};

export const API_ROOT = environment.current.apiBase;
export const AUTH_ROOT = `${API_ROOT.replace('/api', '')}/auth`;

export const getToken = () => {
  token = token || getLocalKey('jwtToken');
  if (token) {
    return token;
  } else {
    return false;
  }
};

export const checkIsVerified = () => {
  let isVerified = false;
  const _token = getToken();
  if (_token) {
    const decoded = jwtDecode(_token);
    isVerified = decoded.security === 'verified';
  }
  return isVerified;
};

export const setToken = _token => {
  token = _token;
  setLocalKey('jwtToken', _token);
};

export const isViewAsClient = () => !!imitateClientId;

export const setImitateClientIdAPI = newClientId => {
  imitateClientId = newClientId || null;
};

/**
 * Custom error handler
 * ie, stop using console.log's!
 */

/* eslint-disable no-console */
export const ErrorHandler = {
  log: msg => (environment.isProduction ? null : console.log(msg)),
  warn: msg => (environment.isProduction ? null : console.warn(msg)),
  error: err => (environment.isProduction ? null : console.error(err)),
  bug: err => {
    if (!environment.isTesting) {
      bugsnagClient.notify(err);
    }
    if (!environment.isProduction) {
      console.error(err);
    }
  },
};
/* eslint-enable no-console */

/* memoise prev ref*/
function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isUnchanged = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isUnchanged) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isUnchanged ? previous : next;
}

export const usePromise = (promise, promiseProps, isDisabled) => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // here we needed to fix previous props because they are passed as an object,
  // so technically every time they are "new" because two objects are always different,
  // so basically we just need to compare them to ensure they are in fact differen, and
  // only update if they are in fact different.
  const memProps = useMemoCompare(promiseProps, (a, b) => isEqual(a, b));

  useEffect(() => {
    // need to prevent attempting to set state on unmounted components
    let unmounted = false;

    async function fetchData() {
      if (!unmounted) {
        setIsLoading(true);
      }
      let res = null;
      try {
        if (isDisabled) {
          throw new Error('disabled', { status: 'disabled' });
        }
        res = await promise(memProps);
        if (!unmounted) {
          setResponse(res);
        }
      } catch (err) {
        if (err?.status === 404) {
          bugsnagClient.leaveBreadcrumb(
            'Fetch Hook - Failed HTTP request - 404',
            {
              requestName: promise?.name,
            }
          );
          ErrorHandler.bug(err);
        }
        if (err?.status === 500) {
          bugsnagClient.leaveBreadcrumb(
            'Fetch Hook - Failed HTTP request - 500',
            {
              requestName: promise?.name,
            }
          );
          ErrorHandler.bug(err);
        }
        if (!unmounted) {
          setError(err);
        }
      }
      if (!unmounted) {
        setIsLoading(false);
      }
    }
    fetchData();
    // below, we should re-fetch if the props change. ie date pickers and such.
    // to ponder: should we also watch for the promise itself to change?

    // on dismount this return callback gets called
    return () => {
      unmounted = true;
    };
  }, [memProps, promise, isDisabled]);
  return { response, isLoading, error };
};

// *****************************************************
// Interceptors / middleware
// *****************************************************

// if we have a token set, use it on the request
const setTokenPlugin = req => {
  getToken();
  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }
};

const clearBanner = () => {
  if (store.getState().banner.bannerIsVisible) {
    store.dispatch({ type: BANNER_HIDE });
  }
};

const timeoutBanner = intercept(err => {
  if (err && err.timeout) {
    bugsnagClient.notify(err);
    store.dispatch({
      type: BANNER_SHOW,
    });
  }
});

// if we get a token on response, use it, or if 401, redirect to login
const authCheckIntercept = intercept((err, res) => {
  if (res && res.headers) {
    if (res.headers.authorization) {
      setToken(res.headers.authorization);
    } else if (res.headers.Authorization) {
      setToken(res.headers.Authorization);
    }
  }
  if (err && err.response && err.response.status === 401) {
    if (window.location.pathname !== '/sessions/login') {
      history.push('/timeout');
    }
  }
});

// if we get a token on response, use it, no redirect
const authCheckInterceptNoRedirect = intercept((err, res) => {
  if (res && res.headers) {
    if (res.headers.authorization) {
      setToken(res.headers.authorization);
    } else if (res.headers.Authorization) {
      setToken(res.headers.Authorization);
    }
  }
});

//----

const buildUrl = ({
  version,
  route,
  params,
  preserveEmpties,
  noClientId = false,
}) => {
  let queryParams = params;
  if (imitateClientId) {
    queryParams = {
      ...queryParams,
      client_id: imitateClientId,
      imitate_client: true,
    };
  } else if (!queryParams?.client_id) {
    // ^^ we dont want to overwrite client_id if it has already been set. Tech roadmap assignee lookup for example
    // add client id to all routes unless specifically told not to with 'noClientId' flag
    queryParams = {
      ...queryParams,
      client_id: noClientId ? null : _clientId,
    };
  }

  if (process?.env?.REACT_APP_STATIC_MOCKS === 'true') {
    queryParams = {
      ...queryParams,
      static_data: true,
    };
  }
  const qs = filtersToQueryParams(queryParams, preserveEmpties);
  return `/${version || defaultVersion}${route}${qs ? '?' : ''}${qs}`;
};

// *****************************************************
// Base request setup
// *****************************************************
const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(setTokenPlugin)
      .use(authCheckIntercept),
  getText: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(setTokenPlugin)
      .use(clearBanner)
      .use(authCheckIntercept)
      .responseType('blob')
      .set('accept', 'text/plain'),
  getCSV: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(setTokenPlugin)
      .use(clearBanner)
      .use(authCheckIntercept)
      .responseType('blob')
      .set('accept', 'text/csv'),
  getPDF: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(setTokenPlugin)
      .use(clearBanner)
      .use(authCheckIntercept)
      .responseType('blob')
      .set('accept', 'application/pdf'),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .set('accept', 'application/json')
      .use(setTokenPlugin)
      .use(clearBanner)
      .use(authCheckIntercept)
      .timeout({
        response: 40000,
      })
      .use(timeoutBanner),
  getNoLogout: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(setTokenPlugin)
      .use(clearBanner)
      .use(authCheckInterceptNoRedirect)
      .timeout({
        response: 40000,
      })
      .use(timeoutBanner),
  getQuery: (url, query) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .query(query)
      .use(setTokenPlugin)
      .use(authCheckIntercept),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(setTokenPlugin)
      .use(authCheckIntercept),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(setTokenPlugin)
      .use(authCheckIntercept),
  patch: (url, body) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(setTokenPlugin)
      .use(authCheckIntercept),
  postNoLogout: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(setTokenPlugin)
      .use(authCheckInterceptNoRedirect),
  postAuth: (url, body) => superagent.post(`${AUTH_ROOT}${url}`, body),
};

// *****************************************************
// Auth
// *****************************************************
export const Auth = {
  emailCheck: email =>
    requests.postAuth('/email_check', {
      email,
    }),

  login: (email, password) =>
    requests.postNoLogout(
      buildUrl({
        version: apiVersion.Auth.login,
        route: '/sessions/login',
        noClientId: true,
      }),
      {
        email,
        password,
      }
    ),

  /**
   * used to test / refresh the elevated token
   */
  refreshToken: () =>
    requests.postNoLogout(
      buildUrl({
        version: apiVersion.Auth.refreshToken,
        route: '/sessions/refresh_token',
        noClientId: true,
      })
    ),

  /**
   * When sent without a code, requests a secure login SMS code. If sms_code is included, attempts to authenticate a user for secure access.
   * @param {string} [code] code received by SMS to validate
   * @returns {Promise}
   */
  secureLogin: code =>
    requests.postNoLogout(
      buildUrl({
        version: apiVersion.Auth.secureLogin,
        route: '/sessions/login',
        noClientId: true,
      }),
      {
        sms_code: code || null,
      }
    ),
  timeout: () => {
    token = null;
  },

  logout: () => {
    token = null;
  },
  addMFA: () =>
    requests.postNoLogout(
      buildUrl({
        version: apiVersion.Auth.addMFA,
        route: '/sessions/add_totp',
        noClientId: true,
      })
    ),
  sendSMS: () =>
    requests.postNoLogout(
      buildUrl({
        version: apiVersion.Auth.sendSMS,
        route: '/sessions/send_sms',
        noClientId: true,
      })
    ),
  verifyOTP: _token => {
    let route = '/sessions/verify_login';
    return requests
      .postNoLogout(
        buildUrl({
          version: apiVersion.Auth.verifyOTP,
          route,
          noClientId: true,
        }),
        {
          token: _token,
        }
      )
      .use(authCheckInterceptNoRedirect);
  },
  verifySMS: _token => {
    let route = '/sessions/verify_login';
    return requests
      .postNoLogout(
        buildUrl({
          version: apiVersion.Auth.verifySMS,
          route,
          noClientId: true,
        }),
        {
          token: _token,
        }
      )
      .use(authCheckInterceptNoRedirect);
  },

  finalizeADLogin: authKey =>
    requests.post(
      buildUrl({
        version: apiVersion.Auth.finalizeADLogin,
        route: '/sessions/finalize_ad_login',
        noClientId: true,
      }),
      { cache_key: authKey }
    ),
};

/**********************************************************************
 * Documents
 **********************************************************************/
export const Docs = {
  get: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Documents.get,
        route: '/documents',
        noClientId: true,
      })
    ),
  download: ({ documentId, fileType }) => {
    const url = buildUrl({
      version: apiVersion.Documents.download,
      route: `/documents/${documentId}`,
    });

    let request;
    switch (fileType) {
      case 'csv':
        request = requests.getCSV;
        break;
      case 'pdf':
        request = requests.getPDF;
        break;
      default:
        request = requests.get;
    }

    return request(url);
  },
};
/**********************************************************************
 * Clients
 **********************************************************************/
export const Clients = {
  get: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Clients.get,
        route: '/clients',
        noClientId: true,
      })
    ),
  getById: id =>
    requests.get(
      buildUrl({
        version: apiVersion.Clients.getById,
        route: `/clients/${id}`,
        noClientId: true,
      })
    ),
  getIntegrations: clientId =>
    requests.get(
      buildUrl({
        version: apiVersion.Clients.getIntegrations,
        route: `/clients/${clientId}/integrations`,
        noClientId: true,
      })
    ),
  getClientIntegration: ({ clientId, integrationId }) =>
    requests.get(
      buildUrl({
        version: apiVersion.Clients.getClientIntegration,
        route: `/clients/${clientId}/integrations/${integrationId}`,
        noClientId: true,
      })
    ),
  getFeatures: id =>
    requests.get(
      buildUrl({
        version: apiVersion.Clients.getFeatures,
        route: `/clients/${id}/features`,
      })
    ),
  addFeature: ({ clientId, featureId, status }) =>
    requests.put(
      buildUrl({
        version: apiVersion.Clients.addFeature,
        route: `/clients/${clientId}/features/${featureId}`,
        noClientId: true,
      }),
      { feature: { id: featureId, status } }
    ),
  updateFeature: ({ clientId, featureId, status }) =>
    requests.patch(
      buildUrl({
        version: apiVersion.Clients.updateFeature,
        route: `/clients/${clientId}/features/${featureId}`,
        noClientId: true,
      }),
      { feature: { id: featureId, status } }
    ),
  addIntegration: ({ clientId, integrationId, integrationName, fields }) =>
    requests.put(
      buildUrl({
        version: apiVersion.Clients.addIntegration,
        route: `/clients/${clientId}/integrations/${integrationId}`,
        noClientId: true,
      }),
      { integration: { id: integrationId, name: integrationName, fields } }
    ),
  updateIntegration: ({ clientId, integrationId, integrationName, fields }) =>
    requests.patch(
      buildUrl({
        version: apiVersion.Clients.updateIntegration,
        route: `/clients/${clientId}/integrations/${integrationId}`,
        noClientId: true,
      }),
      { integration: { id: integrationId, name: integrationName, fields } }
    ),
  updateAuthenticationMethod: ({ authenticationMethod }) =>
    requests.post(
      buildUrl({
        version: apiVersion.Clients.updateAuthenticationMethod,
        route: `/clients/update_sso`,
        params: { sso_method: authenticationMethod },
      }),
      {}
    ),
  deleteIntegration: ({ clientId, integrationId }) =>
    requests.del(
      buildUrl({
        version: apiVersion.Clients.deleteIntegration,
        route: `/clients/${clientId}/integrations/${integrationId}`,
        noClientId: true,
      })
    ),
  requestFeature: ({ featureName }) =>
    requests.post(
      buildUrl({
        version: apiVersion.Clients.requestFeature,
        route: '/contact/request_feature',
      }),
      { customer_id: _clientId, feature_name: featureName }
    ),
  contactAboutEOL: () =>
    requests.post(
      buildUrl({
        version: apiVersion.Clients.contactAboutEOL,
        route: '/contact/end_of_support',
        noClientId: true,
      }),
      {}
    ),
  getOnboardingItems: clientId =>
    requests.get(
      buildUrl({
        version: apiVersion.Clients.getOnboardingItems,
        route: `/clients/${clientId}/onboardings`,
      })
    ),
  updateOnboardingItemVisibility: ({ clientId, itemId, isVisible }) =>
    requests.patch(
      buildUrl({
        version: apiVersion.Clients.updateOnboardingItemVisibility,
        route: `/clients/${clientId}/onboardings/${itemId}`,
      }),
      {
        is_visible: isVisible,
      }
    ),
  updateOnboardingItemStatus: ({ clientId, itemId, status }) =>
    requests.patch(
      buildUrl({
        version: apiVersion.Clients.updateOnboardingItemStatus,
        route: `/clients/${clientId}/onboardings/${itemId}`,
      }),
      {
        status: status,
      }
    ),
};

/**********************************************************************
 * Devices
 **********************************************************************/
export const Devices = {
  all: ({ filters, siteId }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Devices.all,
        route: '/devices',
        params: { ...queryString, site_id: siteId },
      })
    );
  },
  details: ({ deviceId, filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Devices.details,
        route: `/devices/${deviceId}`,
        params: { ...queryString },
      })
    );
  },
  status: ({ deviceId, filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Devices.status,
        route: `/devices/${deviceId}/status`,
        params: { ...queryString },
      })
    );
  },
};

/**********************************************************************
 * Backups
 **********************************************************************/
export const Backups = {
  repositories: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Backups.repositories,
        route: '/backups/repositories',
      })
    );
  },
  jobs: ({ snapshotDate }) => {
    return requests.get(
      buildUrl({
        version: apiVersion.Backups.jobs,
        route: '/backups/jobs',
        params: { snapshot_date: snapshotDate },
      })
    );
  },
  jobDates: ({ year, month }) => {
    return requests.get(
      buildUrl({
        version: apiVersion.Backups.jobDates,
        route: '/backups/job_dates',
        params: { year, month },
      })
    );
  },
};

/***************************************************************
 * Lifecycle
 ***************************************************************/
export const LifeCycle = {
  lifeCycle: ({ refreshStatus, operatingSystem, deviceCategory, deviceType }) =>
    requests.get(
      buildUrl({
        version: apiVersion.LifeCycle.lifeCycle,
        route: '/lifecycle',
        params: {
          device_type: deviceType,
          device_category: deviceCategory,
          operating_system: operatingSystem,
          refresh_status: refreshStatus,
        },
      })
    ),

  getEOLDevices: () =>
    requests.get(
      buildUrl({
        version: apiVersion.LifeCycle.eolDevices,
        route: '/lifecycle/end_of_life',
      })
    ),
};

/********************************************************************
 * Patching *NEW*  routes
 ********************************************************************/
export const Patching = {
  summary: ({ filters, siteId }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Patching.summary,
        route: '/patching/summary',
        params: { ...queryString, site_id: siteId },
      })
    );
  },
  deviceSummary: ({ filters, siteId }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Patching.deviceSummary,
        route: '/patching/device_summary',
        params: { ...queryString, site_id: siteId },
      })
    );
  },
  details: ({ filters, deviceId }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Patching.details,
        route: '/patching/detail',
        params: { ...queryString, device_id: deviceId },
      })
    );
  },
};

/**********************************************************************
 * Admin
 **********************************************************************/

export const Admin = {
  notifications: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Admin.notifications,
        route: '/admin/notification_banner',
      })
    );
  },
};

/**********************************************************************
 * Dashboard
 **********************************************************************/

export const Dashboard = {
  clientTeam: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.clientTeam,
        route: '/dashboard/client_team',
      })
    );
  },
  vulnerableDevices: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.vulnerableDevices,
        route: '/dashboard/vulnerable_devices',
      })
    );
  },
  dnsBlockedCount: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.dnsBlockedCount,
        route: '/dashboard/dns_security_blocks',
      })
    );
  },
  threatsBlocked: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.threatsBlocked,
        route: '/dashboard/network_threats_blocked',
      })
    );
  },
  threatsBlockedByCountry: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.threatsBlockedByCountry,
        route: '/dashboard/network_threat_locations',
      })
    );
  },
  patchingCounts: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.patchingCounts,
        route: '/dashboard/patching_counts',
      })
    ),
  serviceTickets: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.serviceTickets,
        route: '/dashboard/ticket_counts',
      })
    ),
  refreshes: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.refreshes,
        route: '/dashboard/upcoming_device_refreshes',
      })
    ),
  serverUtilization: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.serverUtilization,
        route: '/dashboard/server_utilization',
      })
    ),
  workstationUtilization: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.workstationUtilization,
        route: '/dashboard/workstation_utilization',
      })
    ),

  compromisedAccounts: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.compromisedAccounts,
        route: '/dashboard/compromised_accounts',
      })
    ),

  csatPercentage: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.csat,
        route: '/dashboard/satisfaction_percentage',
      })
    ),

  malwareCount: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.malwareCount,
        route: '/dashboard/malicious_events',
      })
    ),
  endpointProtection: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.endpointProtection,
        route: '/dashboard/endpoint_protection',
      })
    ),
  securityIncidents: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.securityIncidents,
        route: '/dashboard/security_incidents',
      })
    ),
  emailSecurity: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.emailSecurity,
        route: '/dashboard/email_security',
      })
    ),
  workstationCount: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.workstationCount,
        route: '/dashboard/workstation_counts',
      })
    ),
  serverCount: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.serverCount,
        route: '/dashboard/server_counts',
      })
    ),
  patchingStatus: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.patchingStatus,
        route: '/dashboard/patching_status',
      })
    ),
  endpointProtectionStatus: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.endpointProtectionStatus,
        route: '/dashboard/endpoint_protection_status',
      })
    ),
  topSupportContracts: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.topSupportContracts,
        route: '/dashboard/top_support_contracts',
        params: { ...queryString },
      })
    );
  },
  incidentsCount: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.incidentsCount,
        route: '/dashboard/incidents_count',
      })
    ),
  requestsCount: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.requestsCount,
        route: '/dashboard/requests_count',
      })
    ),
  incidentTypeDistribution: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.incidentTypeDistribution,
        route: '/dashboard/incident_type_distribution',
      })
    ),
  ticketCategories: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.ticketCategories,
        route: '/dashboard/ticket_categories',
      })
    ),
  incidentsResolved: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.incidentsResolved,
        route: '/dashboard/incidents_resolved',
        params: { ...queryString },
      })
    );
  },
  topIncidentContacts: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Dashboard.topIncidentContacts,
        route: '/dashboard/top_incident_contacts',
        params: { ...queryString },
      })
    );
  },
  deviceWarranty: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.deviceWarranty,
        route: '/dashboard/device_warranty_expirations',
      })
    ),
  onboardingProgress: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Dashboard.onboardingProgress,
        route: '/dashboard/onboarding_progress',
      })
    ),
};

/**********************************************************************
 * Seats
 **********************************************************************/
export const Seats = {
  all: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Seats.all,
        route: '/seats',
        params: { ...queryString },
      })
    );
  },
  countHistory: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Seats.countHistory,
        route: '/seats/summaries',
      })
    );
  },
  dashboardStats: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.Seats.dashboardStats,
        route: '/seats/summaries',
      })
    );
  },
};

/**********************************************************************
 * Security
 **********************************************************************/
export const Security = {
  incidentsV2: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Security.incidentsV2,
        route: '/security/incidents',
        params: { ...queryString },
      })
    );
  },
  incidents: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Security.incidents,
        route: '/security/incidents',
        params: { ...queryString },
      })
    );
  },
  incidentSummaryByCategory: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Security.incidentSummaryByCategory,
        route: '/security/incidents/summary_by_category',
        params: { ...queryString },
      })
    );
  },
  incidentSummaryOverTime: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Security.incidentSummaryByCategory,
        route: '/security/incidents/summary_over_time',
        params: { ...queryString },
      })
    );
  },
  remediations: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Security.remediations,
        route: '/security/remediations',
        params: { ...queryString },
      })
    );
  },
  remediationMetrics: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Security.remediationMetrics,
        route: '/security/remediations/metrics',
        params: { ...queryString },
      })
    );
  },
  remediationDetails: ({ id }) => {
    return requests.get(
      buildUrl({
        version: apiVersion.Security.remediationDetails,
        route: `/security/remediations/${id}`,
      })
    );
  },
};

/******************************************************************
 * Utilization
 ******************************************************************/
export const Utilization = {
  get: ({ type, filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Utilization.get,
        route: `/utilizations/${type}`,
        params: { ...queryString },
      })
    );
  },
  getHistory: ({ type, filters }) => {
    const queryString = queryParamsToFilters(filters);
    const types = ['cpu', 'disk', 'virtual_memory', 'physical_memory'];
    if (!type || !types.includes(type)) {
      ErrorHandler.error(`ERROR::api::getHistory::invalid type ${type}`);
    }
    return requests.get(
      buildUrl({
        version: apiVersion.Utilization.getHistory,
        route: `/utilizations/${type}_history`,
        params: { ...queryString },
      })
    );
  },
};

/**********************************************************************
 * Network Events
 **********************************************************************/
export const NetworkEvents = {
  vpn: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.vpn,
        route: '/network/vpn/sessions',
        params: { ...queryString },
      })
    );
  },
  vpnFailedLogons: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.vpnFailedLogons,
        route: '/network/vpn/failed_logins',
        params: { ...queryString },
      })
    );
  },
  configChanges: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.configChanges,
        route: '/network/device/configuration_changes',
        params: { ...queryString },
      })
    );
  },
  failedLogins: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.failedLogins,
        route: '/network/device/failed_logins',
        params: { ...queryString },
      })
    );
  },
  threatBlocksByDay: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.threatBlocksByDay,
        route: '/network/threat/blocks_by_day',
        params: { ...queryString },
      })
    );
  },
  threatBlocksByHour: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.threatBlocksByHour,
        route: '/network/threat/blocks_by_hour',
        params: { ...queryString },
      })
    );
  },
  threatLocations: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.threatLocations,
        route: '/network/threat/locations',
        params: { ...queryString },
      })
    );
  },

  threatAveragesPerHour: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.threatAveragesPerHour,
        route: '/network/threat/averages_by_hour',
        params: { ...queryString },
      })
    );
  },
  threatAveragesPerDay: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.threatAveragesPerDay,
        route: '/network/threat/averages_by_weekday',
        params: { ...queryString },
      })
    );
  },
  threatTopCountries: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.threatTopCountries,
        route: '/network/threat/top_countries',
        params: { ...queryString },
      })
    );
  },
  internetUtilizationDeviceInterface: () => {
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.internetUtilizationDeviceInterface,
        route: '/network/internet_utilization/device_interfaces',
      })
    );
  },

  internetUtilizationByFiveMinute: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.internetUtilizationByFiveMinute,
        route: '/network/internet_utilization/five_minute_averages',
        params: { ...queryString },
      })
    );
  },
  internetUtilizationByFourHour: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.NetworkEvents.internetUtilizationByFourHour,
        route: '/network/internet_utilization/four_hour_averages',
        params: { ...queryString },
      })
    );
  },
};

/**********************************************************************
 * Malware Events
 **********************************************************************/
export const MalwareEvents = {
  all: ({ start, end }) => {
    return requests.get(
      buildUrl({
        version: apiVersion.MalwareEvents.all,
        route: '/malware_events',
        params: {
          'event_time[start]': start,
          'event_time[end]': end,
        },
      })
    );
  },
  topDevices: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.MalwareEvents.topDevices,
        route: '/malware_events/top_devices',
        params: { ...queryString },
      })
    );
  },
  summaryByDay: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.MalwareEvents.summaryByDay,
        route: '/malware_events/summary_by_day',
        params: { ...queryString },
      })
    );
  },
};

/**********************************************************************
 * Vulnerabilities
 **********************************************************************/
export const Vulnerabilities = {
  all: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Vulnerabilities.all,
        route: '/vulnerabilities/device_overview',
      })
    ),
  byId: ({ id, deviceId }) =>
    requests.get(
      buildUrl({
        version: apiVersion.Vulnerabilities.byId,
        route: `/vulnerabilities/${id}`,
        params: { device_id: deviceId },
      })
    ),
  byDeviceId: ({ deviceId }) =>
    requests.get(
      buildUrl({
        version: apiVersion.Vulnerabilities.byDeviceId,
        route: '/vulnerabilities/device_detail',
        params: { device_id: deviceId },
      })
    ),
  easeOfExploit: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Vulnerabilities.easeOfExploit,
        route: '/vulnerabilities/ease_of_exploit_history',
      })
    ),
  riskScoreOverTime: params => {
    const deviceId = params?.deviceId || '';
    return requests.get(
      buildUrl({
        version: apiVersion.Vulnerabilities.riskScoreOverTime,
        route: '/vulnerabilities/risk_score_history',
        params: { device_id: deviceId },
      })
    );
  },
  basic: params => {
    const deviceId = params?.deviceId || '';
    return requests.get(
      buildUrl({
        version: apiVersion.Vulnerabilities.basic,
        route: `/vulnerabilities/basic${deviceId ? `/${deviceId}` : ''}`,
      })
    );
  },
};

/**********************************************************************
 * Users
 **********************************************************************/
export const Users = {
  // We still pass client_id here because sometimes a corsica user can have a client selected but want to show a list
  // of corsica users instead for roadmap items for example.
  all: client_id =>
    requests.get(
      buildUrl({
        version: apiVersion.Users.all,
        route: '/users',
        params: { client_id },
      })
    ),

  invite: ({ id }) =>
    requests.post(
      buildUrl({
        version: apiVersion.Users.invite,
        route: '/users/invite',
        params: { user_id: id },
        noClientId: true,
      })
    ),
  inviteSSO: ({ id }) =>
    requests.post(
      buildUrl({
        version: apiVersion.Users.invite,
        route: '/users/invite_sso',
        params: { user_id: id },
        noClientId: true,
      })
    ),
  update: ({ user_id, values }) =>
    requests
      .put(
        buildUrl({
          version: apiVersion.Users.update,
          route: `/users/${user_id}`,
          noClientId: true,
        })
      )
      .send({ ...values, id: user_id }),
  resetMFA: ({ user_id, values }) =>
    requests
      .put(
        buildUrl({
          version: apiVersion.Users.resetMFA,
          route: `/users/${user_id}`,
          noClientId: true,
        })
      )
      .send({ ...values, id: user_id }),
  activate: ({ password, code }) =>
    requests
      .post(
        buildUrl({
          version: apiVersion.Users.activate,
          route: '/users/activate',
          noClientId: true,
        })
      )
      .send({
        password,
        invite_code: code,
      }),
  checkInviteCode: inviteCode =>
    requests.get(
      buildUrl({
        version: apiVersion.Users.checkInviteCode,
        route: '/users/verify_invite_code',
        params: { invite_code: inviteCode },
        noClientId: true,
      })
    ),
  checkPasswordResetCode: resetCode =>
    requests.get(
      buildUrl({
        version: apiVersion.Users.checkPasswordResetCode,
        route: '/users/verify_reset_code',
        params: { reset_code: resetCode },
        noClientId: true,
      })
    ),
  requestPasswordReset: ({ email }) =>
    requests
      .post(
        buildUrl({
          version: apiVersion.Users.requestPasswordReset,
          route: '/users/request_password_reset',
          noClientId: true,
        })
      )
      .send({ email }),
  setPassword: ({ password, code }) =>
    requests
      .post(
        buildUrl({
          version: apiVersion.Users.setPassword,
          route: '/users/reset_password',
          noClientId: true,
        })
      )
      .send({
        password,
        reset_code: code,
      }),
};

/**********************************************************************
 * ActiveDirectory
 **********************************************************************/
export const ActiveDirectory = {
  all: ({ selectedDate }) =>
    requests.get(
      buildUrl({
        version: apiVersion.ActiveDirectory.all,
        route: '/active_directory',
        params: { snapshot_date: selectedDate },
      })
    ),
  summary: ({ selectedDate }) =>
    requests.get(
      buildUrl({
        version: apiVersion.ActiveDirectory.summary,
        route: '/active_directory/summary',
        params: { snapshot_date: selectedDate },
      })
    ),
};

/**********************************************************************
 * Ticket
 **********************************************************************/
export const Ticket = {
  transferToCorsica: ticketId =>
    requests.patch(
      buildUrl({
        version: apiVersion.Ticket.transferToCorsica,
        route: '/tickets/transfer_to_corsica',
        params: { ticket_id: ticketId },
      })
    ),
  attributes: clientId =>
    requests.get(
      buildUrl({
        version: apiVersion.Ticket.attributes,
        route: '/tickets/attributes',
        params: clientId ? { client_id: clientId } : {},
      })
    ),
  create: () =>
    requests.post(
      buildUrl({
        version: apiVersion.Ticket.create,
        route: '/tickets',
      })
    ),
  update: (ticketId, payload) =>
    requests.put(
      buildUrl({
        version: apiVersion.Ticket.update,
        route: `/tickets/${ticketId}`,
      }),
      payload
    ),
  createFeedback: ({ feedback, contactId }) =>
    requests.post(
      buildUrl({
        version: apiVersion.Ticket.create,
        route: '/tickets',
        params: {
          contact_id: contactId,
          title: 'Client Portal Feedback',
          queue_id: 61, // DevOps board
          category_id: 330, // Request
          type_id: 471, // *MUST CHANGE*
          sub_issue_type_id: 1019, // *MUST CHANGE*
          description: feedback,
        },
      })
    ),
  reopen: ({ ticketId }) =>
    requests.post(
      buildUrl({
        version: apiVersion.Ticket.reopen,
        route: `/tickets/${ticketId}/reopen`,
      })
    ),
  createNote: ({ ticketId }) =>
    requests.post(
      buildUrl({
        version: apiVersion.Ticket.createNote,
        route: '/tickets/note',
        params: { ticket_id: ticketId },
      })
    ),
  all: ({ status, siteId }) => {
    let params = status ? { site_id: siteId, status } : { site_id: siteId };
    return requests.get(
      buildUrl({
        version: apiVersion.Ticket.all,
        route: '/tickets',
        params,
      })
    );
  },
  details: ({ ticketId, contactId }) =>
    requests.get(
      buildUrl({
        version: apiVersion.Ticket.details,
        route: `/tickets/${ticketId}`,
        params: { contact_id: contactId },
      })
    ),
  monthly: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.Ticket.monthly,
        route: '/tickets/monthly_metrics',
        params: { ...queryString },
      })
    );
  },
  metrics: ({ origin, status, createdBy, priority, siteId }) => {
    return requests.get(
      buildUrl({
        version: apiVersion.Ticket.metrics,
        route: '/tickets/metrics',
        params: {
          site_id: siteId,
          origin,
          status,
          created_by: createdBy,
          priority,
        },
      })
    );
  },
  attachment: ({ attachmentId }) =>
    requests.get(
      buildUrl({
        version: apiVersion.Ticket.monthly,
        route: '/tickets/attachment',
        params: { attachment_id: attachmentId },
      })
    ),
  myTickets: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Ticket.myTickets,
        route: '/tickets/my_tickets',
      })
    ),
};

/**********************************************************************
 * Reports
 **********************************************************************/

export const Reports = {
  meta: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Reports.meta,
        route: '/reports',
      })
    ),
  get: ({ reportId, startDate, endDate, snapshotDate, fileType }) => {
    const url = buildUrl({
      version: apiVersion.Reports.get,
      route: `/reports/${reportId}`,
      params: snapshotDate
        ? {
            'date[snapshot]': snapshotDate,
          }
        : {
            'date[start]': startDate,
            'date[end]': endDate,
          },
    });

    let request;
    switch (fileType) {
      case 'csv':
        request = requests.getCSV;
        break;
      case 'pdf':
        request = requests.getPDF;
        break;
      default:
        request = requests.get;
    }

    return request(url);
  },
  types: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Reports.types,
        route: '/report_types',
      })
    ),
  getByType: ({ fileType, startDate, endDate, reportTypeId, snapshotDate }) => {
    const url = buildUrl({
      version: apiVersion.Reports.getByType,
      route: `/reports.${fileType}/`, //<< the trailing slash looks wrong, but it is not. No touch.
      params: {
        'date[snapshot]': snapshotDate,
        'date[start]': startDate,
        'date[end]': endDate,
        report_type_id: reportTypeId,
      },
    });

    let request;
    switch (fileType) {
      case 'csv':
        request = requests.getCSV;
        break;
      case 'pdf':
        request = requests.getPDF;
        break;
      default:
        request = requests.get;
    }

    return request(url);
  },
};

/**********************************************************************
 * Roadmap
 **********************************************************************/
export const Roadmap = {
  all: filters =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.all,
        route: '/roadmap/items',
        params: queryParamsToFilters(filters),
      })
    ),
  assignees: filters =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.assignees,
        route: '/roadmap/assignees',
        params: queryParamsToFilters(filters),
      })
    ),
  opportunity: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.opportunities,
        route: '/roadmap/opportunities',
      })
    ),
  report: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.report,
        route: '/roadmap/items/report',
      })
    ),
  get: (itemId, filters) =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.all,
        route: `/roadmap/items/${itemId}`,
        params: { ...queryParamsToFilters(filters) },
      })
    ),
  statuses: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.statuses,
        route: '/roadmap/items/statuses',
      })
    ),
  categories: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.categories,
        route: '/roadmap/items/categories',
      })
    ),
  importance: () =>
    requests.get(
      buildUrl({
        version: apiVersion.Roadmap.importance,
        route: '/roadmap/items/importances',
      })
    ),
  create: payload =>
    requests.post(
      buildUrl({
        version: apiVersion.Roadmap.create,
        route: '/roadmap/items',
      }),
      payload
    ),
  update: (id, payload) =>
    requests.put(
      buildUrl({
        version: apiVersion.Roadmap.update,
        route: `/roadmap/items/${id}`,
      }),
      payload
    ),
  delete: id =>
    requests.del(
      buildUrl({
        version: apiVersion.Roadmap.delete,
        route: `/roadmap/items/${id}`,
      })
    ),
  notes: {
    all: ({ itemId, filters }) =>
      requests.get(
        buildUrl({
          version: apiVersion.Roadmap.notes,
          route: '/roadmap/notes',
          params: {
            ...queryParamsToFilters(filters),
            item_id: itemId,
          },
        })
      ),
    get: ({ itemId, noteId, filters }) =>
      requests.get(
        buildUrl({
          version: apiVersion.Roadmap.notes,
          route: `/roadmap/notes/${noteId}`,
          params: {
            ...queryParamsToFilters(filters),
            item_id: itemId,
          },
        })
      ),
    create: ({ itemId, filters, payload }) =>
      requests.post(
        buildUrl({
          version: apiVersion.Roadmap.notes,
          route: '/roadmap/notes',
          params: {
            ...queryParamsToFilters(filters),
            item_id: itemId,
          },
        }),
        payload
      ),
    update: ({ itemId, noteId, filters, payload }) =>
      requests.put(
        buildUrl({
          version: apiVersion.Roadmap.notes,
          route: `/roadmap/notes/${noteId}`,
          params: {
            ...queryParamsToFilters(filters),
            item_id: itemId,
          },
        }),
        payload
      ),
    delete: ({ itemId, noteId }) =>
      requests.del(
        buildUrl({
          version: apiVersion.Roadmap.notes,
          route: `/roadmap/notes/${noteId}`,
          params: { item_id: itemId },
        })
      ),
  },
};

/**********************************************************************
 * DNS Requests
 **********************************************************************/
export const DNSRequests = {
  /**
   * Get a list of networks with dns request counts
   */
  networks: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.DNSRequests.networks,
        route: '/dns_requests/networks',
        params: { ...queryString },
      })
    );
  },
  summaryOverTime: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.DNSRequests.summaryOverTime,
        route: '/dns_requests/summary_over_time',
        params: {
          ...queryString,
        },
      })
    );
  },
  topSecurityBlockedDevices: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.DNSRequests.topSecurityBlockedDevices,
        route: '/dns_requests/top_security_blocked_devices',
        params: { ...queryString },
      })
    );
  },
  topSecurityBlockedDomains: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.DNSRequests.topSecurityBlockedDomains,
        route: '/dns_requests/top_security_blocked_domains',
        params: { ...queryString },
      })
    );
  },
  deviceSummary: ({ filters }) => {
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.DNSRequests.deviceSummary,
        route: '/dns_requests/device_summary',
        params: { ...queryString },
      })
    );
  },
};

/**********************************************************************
 * Security Awareness Training  ~~~ used to be called: `Email Security` ~~~
 **********************************************************************/
export const SecurityAwarenessTraining = {
  all: props => {
    const filters = props?.filters || {};
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.SecurityAwarenessTraining.all,
        route: '/security_awareness_training',
        params: { ...queryString },
      })
    );
  },
  actionSummary: props => {
    const filters = props?.filters || {};
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.SecurityAwarenessTraining.actionSummary,
        route: '/security_awareness_training/action_summary',
        params: { ...queryString },
      })
    );
  },
  phishTestingSummary: props => {
    const filters = props?.filters || {};
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.SecurityAwarenessTraining.phishTestingSummary,
        route: '/security_awareness_training/phish_testing_summary',
        params: { ...queryString },
      })
    );
  },
  highestRiskRecipients: props => {
    const filters = props?.filters || {};
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.SecurityAwarenessTraining.highestRiskRecipients,
        route: '/security_awareness_training/highest_risk_recipients',
        params: { ...queryString },
      })
    );
  },
  detail: props => {
    const filters = props?.filters || {};
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.SecurityAwarenessTraining.detail,
        route: '/security_awareness_training/detail',
        params: { ...queryString },
      })
    );
  },
  detailSummary: props => {
    const filters = props?.filters || {};
    const queryString = queryParamsToFilters(filters);
    return requests.get(
      buildUrl({
        version: apiVersion.SecurityAwarenessTraining.detailSummary,
        route: '/security_awareness_training/detail/summary',
        params: { ...queryString },
      })
    );
  },
};

/**********************************************************************
 * Endpoint Protection
 **********************************************************************/
export const EndpointProtection = {
  all: ({ year, month }) =>
    requests.get(
      buildUrl({
        version: apiVersion.EndpointProtection.all,
        route: '/endpoint_protection',
        params: { year, month },
      })
    ),
};

/**********************************************************************
 * Dark Web Monitoring
 **********************************************************************/
export const DarkWebMonitoring = {
  all: ({ filters }) =>
    requests.get(
      buildUrl({
        version: apiVersion.DarkWebMonitoring.all,
        route: '/security/dark_web_monitoring',
        params: { ...queryParamsToFilters(filters) },
      })
    ),
  remediationStatus: ({ filters }) =>
    requests.get(
      buildUrl({
        version: apiVersion.DarkWebMonitoring.remediationStatus,
        route: '/security/dark_web_monitoring/remediation_status',
        params: { ...queryParamsToFilters(filters) },
      })
    ),
  topSites: ({ filters }) =>
    requests.get(
      buildUrl({
        version: apiVersion.DarkWebMonitoring.topSites,
        route: '/security/dark_web_monitoring/top_sites',
        params: { ...queryParamsToFilters(filters) },
      })
    ),
  topAccounts: ({ filters }) =>
    requests.get(
      buildUrl({
        version: apiVersion.DarkWebMonitoring.topAccounts,
        route: '/security/dark_web_monitoring/top_accounts',
        params: { ...queryParamsToFilters(filters) },
      })
    ),
};

/**********************************************************************
 * CSAT
 **********************************************************************/
export const CSAT = {
  create: ({
    csatName,
    csatComment,
    ticketId,
    contactId,
    accountId,
    marketingConsent,
    source,
  }) =>
    requests.post(
      buildUrl({
        version: apiVersion.CSAT.create,
        route: '/ratings',
      }),
      {
        rating: {
          rating: csatName,
          comment: csatComment,
          account_id: accountId,
          contact_id: contactId,
          ticket_id: ticketId,
          source,
          marketing_consent: marketingConsent,
        },
      }
    ),
  update: ({
    csatName,
    csatComment,
    csatId,
    ticketId,
    contactId,
    accountId,
    marketingConsent,
    source,
  }) =>
    requests.put(
      buildUrl({
        version: apiVersion.CSAT.update,
        route: `/ratings/${csatId}`,
      }),
      {
        rating: {
          rating: csatName,
          comment: csatComment,
          account_id: accountId,
          contact_id: contactId,
          ticket_id: ticketId,
          marketing_consent: marketingConsent,
          source,
        },
      }
    ),
};

/**********************************************************************
 * Security Scores
 **********************************************************************/
export const SecurityScores = {
  overall: () =>
    requests.get(
      buildUrl({
        version: apiVersion.SecurityScores.overall,
        route: '/security_scores/overall_security_score',
      })
    ),
  device: () =>
    requests.get(
      buildUrl({
        version: apiVersion.SecurityScores.device,
        route: '/security_scores/device_security_score',
      })
    ),
  people: () =>
    requests.get(
      buildUrl({
        version: apiVersion.SecurityScores.people,
        route: '/security_scores/people_security_score',
      })
    ),
  network: () =>
    requests.get(
      buildUrl({
        version: apiVersion.SecurityScores.network,
        route: '/security_scores/network_security_score',
      })
    ),
  history: () =>
    requests.get(
      buildUrl({
        version: apiVersion.SecurityScores.history,
        route: '/security_scores/history',
      })
    ),
  summary: () =>
    requests.get(
      buildUrl({
        version: apiVersion.SecurityScores.summary,
        route: '/security_scores/summary',
      })
    ),
};

/**********************************************************************
 * DEV TOOLS
 **********************************************************************/
export const DevToolsAPI = {
  updateUser: fields =>
    requests.post(
      buildUrl({
        version: 'v1',
        route: '/sessions/devtools_update_user',
      }),
      fields
    ),
  updateToken: ({ token }) => {
    setToken(token);
    return requests.post(
      buildUrl({
        version: 'v1',
        route: '/sessions/devtools_update_token',
      }),
      { token }
    );
  },
};

/**********************************************************************
 * Utils
 **********************************************************************/

export const buildUrlFromKeyValPairs = (baseUrl, keyValuePairs) => {
  return keyValuePairs.length > 0
    ? `${baseUrl}?${keyValuePairs
        .map(pair => `${pair.key}=${pair.value}`)
        .join('&')}`
    : baseUrl;
};

export const queryParamsToFilters = url => {
  if (typeof url === 'object') {
    return url;
  }
  const params = {};
  if (url && url.length > 1) {
    let pairs = url.split('?');
    pairs = pairs.length > 1 ? pairs[1].split('&') : pairs[0].split('&');
    pairs.map(pair => {
      pair = pair.split('=');
      if (pair.length === 2) {
        return (params[pair[0]] = decodeURIComponent(pair[1]));
      } else {
        return (params[pair[0]] = '');
      }
    });
  }

  return params;
};

/**
 * Converts an object of filters and values to a query param string suitable for use with the API
 * Expects a filterObject with the names as keys and values as arrays or strings for single values.
 * @param {object} filterObject - ex. {os: ['value 1', 'value 2'], category: 'value 1'}
 * @returns {string} - the query string to append to the API request
 */
export const filtersToQueryParams = (filterObject, preserveEmpties) => {
  const checkEmpties = (val, keepEmpties) =>
    !!(!val && val !== 0 && !keepEmpties);
  if (!filterObject) {
    return '';
  }

  const filters = Object.keys(filterObject);

  if (filters.length === 0) {
    return '';
  }

  const qpArray = filters.map(f => {
    // If the filter has multiple values, we need to create multiple query params for that filter
    // since the api expects filter[]=value1&filter[]=value2
    if (
      typeof filterObject[f] === 'object' &&
      Array.isArray(filterObject[f]) &&
      filterObject[f].length > 1
    ) {
      // if value is falsy and we dont want empties, return null and we will strip nulls later
      const filterArray = filterObject[f].map(val =>
        checkEmpties(val, preserveEmpties) ? null : `${f}[]=${val || ''}`
      );
      return filterArray.join('&');
      // Otherwise, we just return filter=value
    } else {
      // If it's an array but only has one value, grab the first one.
      if (
        typeof filterObject[f] === 'object' &&
        Array.isArray(filterObject[f])
      ) {
        let val = filterObject[f][0];
        // keep val if its a value or zero
        val = !!val || val === 0 ? val : '';
        return checkEmpties(val, preserveEmpties)
          ? null
          : f.indexOf('[') === -1
          ? `${f}[]=${val}`
          : `${f}=${val}`;
      } else {
        let val = filterObject[f];
        val = !!val || val === 0 ? val : '';
        return checkEmpties(filterObject[f], preserveEmpties)
          ? null
          : `${f}=${val}`;
      }
    }
  });

  return qpArray.filter(f => !!f).join('&');
};
